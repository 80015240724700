<template>
  <div>
      <b-sidebar
        id="sidebar-adicionar-playlist"
        :visible="adicionarMusicaPlaylistSideBarAtivo"
        bg-variant="white"
        sidebar-class="sidebar-lg full-width-sidebar"
        shadow
        backdrop
        no-header
        right
        @change="handleSidebarChange"
      >
        <template #default="{ hide }">
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">Adicionar a uma Playlist</h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>

          <b-form @submit.prevent="adicionarHitNaPlaylist" class="mx-2">

            <b-form-group class="mt-2" label-for="hitPlaylists">
              <validation-provider #default="{ errors }" name="hitPlaylists">
                <b-form-input v-model="termoDePesquisa" placeholder="Pesquisar playlists"
                    @input="filterPlaylists"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            
            <vue-element-loading
              :active="respostaApi"
              text="Incluindo na Playlist"
            >
              <img
                src="@/assets/images/logo/logo-loading.gif"
                width="40px"
              >
            </vue-element-loading>

            <div class="mt-2">
              <b-list-group>
                <b-list-group-item
                  v-for="playlist in filteredPlaylists"
                  :key="playlist.id"
                  @click="adicionarHitNaPlaylist(playlist.id)"
                  class="cursor-pointer"
                  :disabled="respostaApi"
                >
                  <img :src="caminhoImagem(playlist.capaPlaylist)" alt="Imagem" class="mr-2" style="width: 50px; height: 50px;" />
                  <span>{{ playlist.nomePlaylist }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>

            <div class="d-flex mt-2">
              <b-button block variant="primary" @click="addPlaylistSidebarAtivo = true">
                <span class="text-nowrap">Criar Playlist</span>
              </b-button>
            </div>
          </b-form>
        </template>
      </b-sidebar>

      <hit-comprador-playlist-add
        :add-playlist-sidebar-ativo.sync="addPlaylistSidebarAtivo"
        @playlist-criada="atualizarPlaylists"
      />
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BSpinner,
  BListGroup, BListGroupItem, 
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import VueElementLoading from 'vue-element-loading'

import HitCompradorPlaylistAdd from './HitCompradorPlaylistAdd.vue'

export default {
  components: {
    BSidebar,
    BListGroup,
    BListGroupItem,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueElementLoading,
    HitCompradorPlaylistAdd
  },
  props: {
    adicionarMusicaPlaylistSideBarAtivo: Boolean,
    hitId: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.usuarioCompradorPlaylist();
  },
  data() {
    return {
      respostaApi: false,
      carregamentoApi: false,
      playlist: [],
      termoDePesquisa: '',
      filteredPlaylists: [],
      playlistSelecionada: null,
      addPlaylistSidebarAtivo: false,
    }
  },
  methods: {  
    atualizarPlaylists() {
      this.usuarioCompradorPlaylist();
    },
    caminhoImagem(capaPlaylist) {
      if (capaPlaylist) {
        return capaPlaylist;
      } else {
        return require('@/assets/images/previas/campanha.png');
      }
    },
    handleSidebarChange(val) {
      this.$emit('update:adicionarMusicaPlaylistSideBarAtivo', val);
    },
    filterPlaylists() {
      if (this.termoDePesquisa.trim() === '') {
        // Se a barra de pesquisa estiver vazia, mostre as 5 primeiras playlists
        this.filteredPlaylists = this.playlist.slice(0, 5);
      } else {
        // Filtra as playlists pelo nome
        this.filteredPlaylists = this.playlist.filter(playlist =>
          playlist.nomePlaylist.toLowerCase().includes(this.termoDePesquisa.toLowerCase())
        );
      }
    },
    usuarioCompradorPlaylist() {
      this.carregamentoApi = true

      this.usuarioId = JSON.parse(localStorage.getItem('usuarioData'))?.sub
      useJwt.playlistListar({
        usuarioId: this.usuarioId,
        page: 0,
        size: 9999,
        sort: "ASC"
      })
      .then(response => {
        this.playlist = response.data.content;
        console.log(this.playlist)
        this.filteredPlaylists = this.playlist.slice(0, 5);
      })
      .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    adicionarHitNaPlaylist(valor) {
      this.respostaApi = true

      let playlistId
      
      if(valor != null ) {
        playlistId = valor;
      } else {
        playlistId = this.playlist.find(playlist => playlist.nomePlaylist === this.playlistSelecionada).id;
      }

      useJwt.playlistAdicionarMusica({
        playlistId: playlistId,
        hitId: this.hitId
      })
        .then(response => {
                  this.$swal({
                      title: 'Hit adicionado!',
                      text: 'O hit foi adicionado à playlist com sucesso.',
                      icon: 'success',
                      customClass: {
                      confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
          })
          this.handleSidebarChange(false)
        })
        .catch(error => {
                this.$swal({
                    title: 'Erro!',
                    text: error.response.data.message,
                    icon: 'error',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
        })
        .finally(() => {
          this.respostaApi = false
        })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@media (max-width: 767.98px) {
  .full-width-sidebar {
    height: 100vh !important; 
    width: 100vw !important;
    position: fixed !important; 
  }
}
</style>
